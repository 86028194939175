import 'twin.macro'
import { Link } from 'gatsby'

import PoweredByGigmade from '../images/svg/powered-by-gigmade.svg'
import Logo from '../images/svg/zhawSusLogo.svg'

export default function Footer() {
  return (
    <footer tw="w-full px-4 md:h-12 bg-grey-2 flex flex-col md:flex-row md:items-center md:justify-between">
      <div tw="relative h-12 py-3 flex items-center gap-x-16">
        <a
          tw="block h-full"
          href="http://www.gigmade.com"
          target="_blank"
          rel="noreferrer"
        >
          <PoweredByGigmade tw="h-full text-white fill-current stroke-current" />
        </a>
        <Logo tw="h-full" />
      </div>

      <Link to={'/upload'} tw="py-3 text-white hover:underline font-bold">
        Upload Data
      </Link>
    </footer>
  )
}
