import React from 'react'

import { ErrorBoundaryCountainer } from './src/components/ErrorBoundaryCountainer'
import { DataProvider } from './src/data'

import '@fontsource/ibm-plex-sans/400.css'
import '@fontsource/ibm-plex-sans/700.css'
import '@fontsource/raleway/variable.css'
import { JsonDataProvider } from './src/data/jsonDataContext'

export const wrapPageElement = ({ element, props }) => {
  if (props.path.includes('upload')) {
    return element
  }

  return (
    <ErrorBoundaryCountainer>
      <DataProvider>{element}</DataProvider>
    </ErrorBoundaryCountainer>
  )
}

export const wrapRootElement = ({ element }) => {
  return <JsonDataProvider>{element}</JsonDataProvider>
}
