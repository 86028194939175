exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-air-bar-chart-js": () => import("./../../../src/pages/air/bar-chart.js" /* webpackChunkName: "component---src-pages-air-bar-chart-js" */),
  "component---src-pages-air-flight-segments-per-organization-tsx": () => import("./../../../src/pages/air/flight-segments-per-organization.tsx" /* webpackChunkName: "component---src-pages-air-flight-segments-per-organization-tsx" */),
  "component---src-pages-air-flight-segments-tsx": () => import("./../../../src/pages/air/flight-segments.tsx" /* webpackChunkName: "component---src-pages-air-flight-segments-tsx" */),
  "component---src-pages-air-index-js": () => import("./../../../src/pages/air/index.js" /* webpackChunkName: "component---src-pages-air-index-js" */),
  "component---src-pages-air-sankey-js": () => import("./../../../src/pages/air/sankey.js" /* webpackChunkName: "component---src-pages-air-sankey-js" */),
  "component---src-pages-air-sunburst-js": () => import("./../../../src/pages/air/sunburst.js" /* webpackChunkName: "component---src-pages-air-sunburst-js" */),
  "component---src-pages-air-time-series-js": () => import("./../../../src/pages/air/time-series.js" /* webpackChunkName: "component---src-pages-air-time-series-js" */),
  "component---src-pages-cars-index-js": () => import("./../../../src/pages/cars/index.js" /* webpackChunkName: "component---src-pages-cars-index-js" */),
  "component---src-pages-energy-index-js": () => import("./../../../src/pages/energy/index.js" /* webpackChunkName: "component---src-pages-energy-index-js" */),
  "component---src-pages-food-index-js": () => import("./../../../src/pages/food/index.js" /* webpackChunkName: "component---src-pages-food-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paper-index-js": () => import("./../../../src/pages/paper/index.js" /* webpackChunkName: "component---src-pages-paper-index-js" */),
  "component---src-pages-train-bar-chart-js": () => import("./../../../src/pages/train/bar-chart.js" /* webpackChunkName: "component---src-pages-train-bar-chart-js" */),
  "component---src-pages-train-index-js": () => import("./../../../src/pages/train/index.js" /* webpackChunkName: "component---src-pages-train-index-js" */),
  "component---src-pages-train-sankey-js": () => import("./../../../src/pages/train/sankey.js" /* webpackChunkName: "component---src-pages-train-sankey-js" */),
  "component---src-pages-train-sunburst-js": () => import("./../../../src/pages/train/sunburst.js" /* webpackChunkName: "component---src-pages-train-sunburst-js" */),
  "component---src-pages-train-time-series-js": () => import("./../../../src/pages/train/time-series.js" /* webpackChunkName: "component---src-pages-train-time-series-js" */),
  "component---src-pages-train-train-segments-per-organization-tsx": () => import("./../../../src/pages/train/train-segments-per-organization.tsx" /* webpackChunkName: "component---src-pages-train-train-segments-per-organization-tsx" */),
  "component---src-pages-train-train-segments-tsx": () => import("./../../../src/pages/train/train-segments.tsx" /* webpackChunkName: "component---src-pages-train-train-segments-tsx" */),
  "component---src-pages-upload-js": () => import("./../../../src/pages/upload.js" /* webpackChunkName: "component---src-pages-upload-js" */),
  "component---src-pages-water-index-js": () => import("./../../../src/pages/water/index.js" /* webpackChunkName: "component---src-pages-water-index-js" */)
}

