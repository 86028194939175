import { useMemo } from 'react'
// TODO: Only use camelCase. Consider https://www.npmjs.com/package/camelcase-keys

import { getYearlyFteForAll, getTopOrgFteGroupedByYear } from './orgFte'
import { useData } from './dataContext'

// TODO: Rename related according to (some relevant functions getTopUnits, getTopOrgFteGroupedByYear):
// Naming for different kinds of org units:
// `orgUnit` (or `accountable..`): A, BC, DAA
// `summedOrgUnit`: ∑A, ∑BA, ∑ABA, perhaps ∑
// `topLevelOrgUnit`: A, B, C (if a sep. name is needed)
// `totalOrg`: ∑ (if a sep. name is needed)

const siteKeys = ['campus', 'code', 'name', 'location', 'ownership']

export function useHelperData() {
  const { orgFte, buildings } = useData()

  const { yearlyFte, topOrgFteGroupedByYear } = useMemo(() => {
    return {
      yearlyFte: getYearlyFteForAll(orgFte),
      topOrgFteGroupedByYear: getTopOrgFteGroupedByYear(orgFte),
    }
  }, [orgFte])

  const { eraBySiteYear, eraByYear } = useMemo(() => {
    return {
      eraBySiteYear: (site, year) => {
        return buildings[year]
          .filter((item) => {
            return siteKeys.some((key) => {
              return item[key] === site
            })
          })
          .reduce((pv, item) => {
            return pv + item['era (m2)']
          }, 0)
      },
      eraByYear: (year) => {
        return buildings[year].reduce((pv, item) => {
          return pv + item['era (m2)']
        }, 0)
      },
    }
  }, [buildings])

  return { yearlyFte, topOrgFteGroupedByYear, eraBySiteYear, eraByYear }
}
