export function createTravelSegmentData<T>(
  travelSegment: Array<Array<unknown>>,
  travelSegmentDataSpec: Array<string>,
  extendedKeys: ReadonlyArray<ReturnType<typeof getKeyData>>
): Array<T> {
  const result = travelSegment.map((m) =>
    transformTravelSegmentItem<T>(m, travelSegmentDataSpec, extendedKeys)
  )

  return result
}

function transformTravelSegmentItem<T>(
  travelSegmentValues: ReadonlyArray<unknown>,
  travelSegmentDataSpec: Array<string>,
  extendedKeys: ReadonlyArray<ReturnType<typeof getKeyData>>
): T {
  const keyNames = extendedKeys.map((f) => f.key)

  const result = travelSegmentValues.reduce<T>(
    (finalTravelSegment: T, value, index) => {
      const key = travelSegmentDataSpec[index]

      const isKeyValueValid =
        value !== null && value !== undefined && !isNaN(+value)

      if (isKeyValueValid && keyNames.includes(key)) {
        const travelSegmentKey = extendedKeys.find((k) => k.key === key)
        const extendedKeyObject = travelSegmentKey.mappedData.get(+value)

        return {
          ...finalTravelSegment,
          [key]: value, // Also add __x_key data
          ...extendedKeyObject,
        }
      }

      return {
        ...finalTravelSegment,
        [key]: value,
      }
    },
    {} as T
  )

  return result
}

export function getKeyData(
  data: ReadonlyArray<Array<unknown>>,
  dataSpecs: ReadonlyArray<string>,
  key: string
) {
  type TItem = Record<(typeof dataSpecs)[number], (typeof data)[number][number]>

  const obj = listToObject<TItem>(data, dataSpecs)

  const mappedData = obj?.reduce((acc, curr) => {
    const { [key]: _key, ...rest } = curr

    if (typeof _key === 'number') {
      return acc.set(_key, rest)
    }

    return acc
  }, new Map<number, TItem>())

  return {
    mappedData,
    key,
  }
}

export function listToObject<T extends Record<string, unknown>>(
  array: ReadonlyArray<Array<unknown>> | undefined,
  specs: ReadonlyArray<keyof T | string> | undefined
): Array<T> | null {
  if (!array || !specs) {
    return null
  }

  const objects = array.map((item) => itemFromSpec<T>(item, specs))

  return objects
}

export function dictToObject<
  T extends Record<string, K>,
  K = Record<string, unknown>
>(
  dict: Record<string, Array<unknown>>,
  specs: [string, ReadonlyArray<keyof K | string>]
): T {
  const entries = Object.entries(dict)
  const result = entries.reduce<T>((all, current) => {
    const [key, values] = current

    const valueSpec = specs[1]
    const newValue = itemFromSpec<K>(values, valueSpec)

    return {
      ...all,
      [key]: newValue,
    }
  }, {} as T)

  return result
}

function itemFromSpec<T extends Record<string, unknown>>(
  item: ReadonlyArray<unknown>,
  specs: ReadonlyArray<keyof T | string>
) {
  const result = item.reduce<T>((acc: T, current: unknown, index) => {
    const key = specs[index]

    return {
      ...acc,
      [key]: current,
    }
  }, {} as T)

  return result
}

export function downloadAsCSV(content: string, fileName: string) {
  const blob = new Blob([content], { type: 'text/csv' })
  const objectURL = URL.createObjectURL(blob)

  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = objectURL
  anchor.click()
  URL.revokeObjectURL(objectURL)
}
