import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Helmet } from 'react-helmet'
import 'twin.macro'

import '../static/fonts/fonts.css'

// TODO: Find a better way to only import during dev mode/disable
// Disabled gatsby error overlay blocking viewing error boundary
// See https://github.com/gatsbyjs/gatsby/issues/20420#issuecomment-971810190
import './gatsby-layout.css'

import CSSReset from './CSSReset'
import Header from './Header'
import Footer from './Footer'

export function BaseLayout({ children, ...rest }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const { title, description } = site.siteMetadata

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <html lang="en" />
      </Helmet>
      <CSSReset />
      <StyledContainer {...rest}>{children}</StyledContainer>
    </>
  )
}

export function StyledContainer({ children, ...rest }) {
  return (
    <>
      <div
        {...rest}
        tw="min-h-screen lg:h-screen flex flex-col lg:overflow-hidden"
      >
        <Header />
        <main tw="w-full flex-1 flex flex-col">{children}</main>
      </div>
      <Footer />
    </>
  )
}
