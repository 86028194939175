import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import { MdCancel } from 'react-icons/md'
import 'twin.macro'

import { BaseLayout } from './BaseLayout'
import { ZodError } from 'zod'
import { ReactNode } from 'react'

export function ErrorBoundaryCountainer({ children }: { children: ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  )
}
function ErrorFallback({ error }: FallbackProps) {
  if (error instanceof ZodError) {
    const messages = error.issues.map((e) => {
      const joinedPaths = e.path.join('.')

      if (e.code === 'custom') {
        if (e.message === 'missing_key') {
          const key = e.params?.key

          return `Missing key in ${joinedPaths}: ${key}`
        }
      }

      return `${e.message}: ${joinedPaths}`
    })

    return (
      <StyledLayout>
        <ul tw="text-red-600 col-start-2 flex flex-col gap-1">
          {messages.map((m, i) => (
            <li key={i} tw="list-disc list-inside">
              {m}
            </li>
          ))}
        </ul>
        <a
          href="/upload"
          tw="mt-4 col-start-2 font-bold underline hover:text-red-600"
        >
          View upload
        </a>
      </StyledLayout>
    )
  }

  return (
    <StyledLayout>
      <p tw="text-red-600 col-start-2 flex flex-col gap-1">{error.message}</p>
    </StyledLayout>
  )
}

function StyledLayout({ children }: { children: ReactNode }) {
  return (
    <BaseLayout>
      <div tw="w-screen h-screen pt-8 pb-28 flex flex-col justify-center items-center">
        <div tw="text-red-700 px-6 py-4 bg-red-100 rounded grid grid-cols-[auto,1fr] items-center gap-2 w-10/12 max-w-prose overflow-x-auto">
          <MdCancel tw="w-5 h-5" />
          <h2 tw="text-xl font-semibold">Error</h2>
          {children}
        </div>
      </div>
    </BaseLayout>
  )
}
