import { rollups, sum } from 'd3-array'

import type {
  OrgFte,
  YearlyFte,
  TopOrgFteGroupedByYear,
  TFteCount,
  TOrgUnit,
  TYear,
} from './types'

const TOP_PATTERN = /^∑.$/

type TYearOrgUnitsArray = [TYear, Array<[TOrgUnit, TFteCount]>]
type TYearOrgUnits = Record<TYear, Record<TOrgUnit, TFteCount>>

export function getOrgFte(
  hrEntries: ReadonlyArray<
    Required<{
      year: number
      fte: number
      org_unit: string
    }>
  > | null,
  config?: {
    showDuplicates?: boolean
  }
) {
  if (!hrEntries) {
    return {}
  }

  const showDuplicates = config?.showDuplicates

  const orgUnitsFteArray = getOrgUnitsFteArrayFromHrEntries(hrEntries)

  const simpleOrgFte = getSimpleOrgFte(orgUnitsFteArray)
  const summedOrgFte = getSummedOrgFte(orgUnitsFteArray, showDuplicates)

  const availableYears = getAvailableYears(orgUnitsFteArray)
  const result = availableYears.reduce<TYearOrgUnits>((all, year) => {
    all[year] = Object.assign(summedOrgFte[year], simpleOrgFte[year])

    return all
  }, {})

  return result
}

export function getSimpleOrgFteFromHrEntries(
  hrEntries: ReadonlyArray<
    Required<{
      year: number
      fte: number
      org_unit: string
    }>
  > | null
) {
  if (!hrEntries) {
    return {}
  }

  const orgUnitsFteArray = getOrgUnitsFteArrayFromHrEntries(hrEntries)

  const simpleOrgFte = getSimpleOrgFte(orgUnitsFteArray)

  return simpleOrgFte
}

export function getSummedOrgFteFromHrEntries(
  hrEntries: ReadonlyArray<
    Required<{
      year: number
      fte: number
      org_unit: string
    }>
  > | null,
  config?: {
    showDuplicates?: boolean
  }
) {
  if (!hrEntries) {
    return {}
  }

  const showDuplicates = config?.showDuplicates

  const orgUnitsFteArray = getOrgUnitsFteArrayFromHrEntries(hrEntries)

  const summedOrgFte = getSummedOrgFte(orgUnitsFteArray, showDuplicates)

  return summedOrgFte
}

/** Department names are nested, i.e. department XYZ is under XY.
 * If we have a sub-department XYZ, but no super-department XY, this is an issue and needs correction.
 * For example: With no org 'LD', but orgs 'LDA' and 'LDB' the cockpit cannot present a correct
 * sunburst ring for 'level 2 departments'.
 */
function extendMissing(
  yearOrgUnits: TYearOrgUnitsArray
): [TYearOrgUnitsArray, Array<string>] {
  const orgSet = new Set(yearOrgUnits[1].map(([org_unit]) => org_unit))
  const missingOrgs = []

  for (const org of orgSet) {
    for (let i = 1; i < org.length; i++) {
      const ancestor = org.slice(0, i)

      if (!orgSet.has(ancestor)) {
        missingOrgs.push(ancestor)
      }
    }
  }

  const expandedMissingOrgs: TYearOrgUnitsArray[1] = missingOrgs.map(
    (org: string) => [
      org,
      {
        fte: 0,
        count: 0,
      },
    ]
  )

  const expandedYearOrgUnits: TYearOrgUnitsArray = [
    yearOrgUnits[0],
    [...yearOrgUnits[1], ...expandedMissingOrgs],
  ]

  return [expandedYearOrgUnits, missingOrgs]
}

export function getOrgUnitsFteArrayFromHrEntries(
  hrEntries: Parameters<typeof getOrgFte>[0]
) {
  const result: ReadonlyArray<TYearOrgUnitsArray> = rollups(
    hrEntries,
    (v) => ({
      fte: sum(v, (d) => d.fte),
      count: v.length,
    }),
    (d) => d.year,
    (d) => d.org_unit
  ).map(([year, nestedItems]) => [year, nestedItems])

  return result
}

export function getSummedOrgFte(
  orgUnitsFteArray: ReadonlyArray<TYearOrgUnitsArray>,
  // showDuplicates will show all summed org units
  // e.g return ∑B if there is only B in org units, with no other sub org unit like BA
  // TODO: Add comment on why we need this flag
  showDuplicates = false
) {
  const years = getAvailableYears(orgUnitsFteArray)

  const result = years.reduce<TYearOrgUnits>((allOrgFte, currentYear) => {
    const originalYearOrgUnits = orgUnitsFteArray.find(
      (year) => year[0] === currentYear
    )
    const [yearOrgUnitsArray] = extendMissing(originalYearOrgUnits)
    const [, rows] = yearOrgUnitsArray

    const subDeps = rows.reduce((allDeps, currentRow) => {
      const unit = currentRow[0]

      const subs = unit.split('').map((_, i) => unit.slice(0, i + 1))

      subs.forEach((l) => {
        const toMerge: Array<typeof currentRow> = allDeps.has(l)
          ? allDeps.get(l)
          : []
        toMerge.push(currentRow)
        allDeps.set(l, toMerge)
      })

      return allDeps
    }, new Map())

    const yearOrgUnits: Record<TOrgUnit, { fte: number; count: number }> =
      rows.reduce((yearRows, row) => {
        const [orgUnit] = row

        // Get a list of all org units that starts with this `org`
        const withSubDeps = subDeps.get(orgUnit)

        // Top orgs should have more than one sub org unit
        // e.g. In A, C, CA - Only ∑C is a top  unit
        if (!showDuplicates && withSubDeps.length === 1) {
          return yearRows
        }

        const fteAll = sum(withSubDeps, (d) => d[1].fte)
        const countAll = sum(withSubDeps, (d) => d[1].count)

        const sumOrgUnit = `∑${orgUnit}`

        yearRows[sumOrgUnit] = {
          fte: fteAll,
          count: countAll,
        }

        return yearRows
      }, {})

    // Finally, a top-level FTE count across the whole organization
    const topLetterOrgs = Object.keys(yearOrgUnits).filter((k) =>
      k.match(TOP_PATTERN)
    )

    const totalOrg = {
      '∑': {
        fte: sum(topLetterOrgs, (d) => yearOrgUnits[d].fte),
        count: sum(topLetterOrgs, (d) => yearOrgUnits[d].count),
      },
    }

    allOrgFte[currentYear] = Object.assign(yearOrgUnits, totalOrg)

    return allOrgFte
  }, {})

  return result
}

export function getSimpleOrgFte(
  orgUnitsFteArray: ReadonlyArray<TYearOrgUnitsArray>
) {
  // The simple dict which is just individual orgs, no recursion for the sum.
  const result = orgUnitsFteArray.reduce<TYearOrgUnits>((all, current) => {
    const [year, rows] = current

    all[year] = Object.fromEntries(rows)

    return all
  }, {})

  return result
}

function getAvailableYears(
  orgUnitsFteArray: ReadonlyArray<TYearOrgUnitsArray>
) {
  const result = orgUnitsFteArray.map(([year]) => year).sort()

  return result
}

// TODO: Move below functions elsewhere?
/*
 * Get total FTE by all departments for each year
 */
export function getYearlyFteForAll(orgFte: OrgFte): YearlyFte {
  const result = Object.fromEntries(
    Object.entries(orgFte).map(([key, value]) => {
      return [key, value['∑'].fte]
    })
  )
  return result
}

/*
 * Get FTE values of all departments from every year
 */
export function getTopOrgFteGroupedByYear(
  orgFte: OrgFte,
  shouldFilterByTop = true
): TopOrgFteGroupedByYear {
  const result = Object.fromEntries(
    Object.entries(orgFte).map(([key, value]) => {
      const filtered = shouldFilterByTop
        ? Object.entries(value)
            // Filter by highest level of organizations
            .filter(([key]) => key.match('^∑.*$'))
        : Object.entries(value)

      const fteByOrg = filtered.reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value.fte,
        }),
        {}
      )

      return [key, fteByOrg]
    })
  )
  return result
}
