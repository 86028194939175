import React from 'react'
import { Link } from 'gatsby'
import { globalHistory } from '@reach/router'
import tw from 'twin.macro'

import Data from '../images/svg/data.svg'
import Experiments from '../images/svg/experiments.svg'
import HeaderTab from './HeaderTab'
import InfoModal, {
  InfoHeading,
  InfoList,
  InfoListItem,
  InfoParagraph,
} from './InfoModal'
import ExternalLink from './ExternalLink'
import { useJsonData } from '../data'

const routes = [
  {
    link: '/',
    name: 'Dashboard',
    children: [
      {
        link: '/upload',
        name: 'Upload',
      },
      // TravelSegment
      {
        link: '/air',
        name: 'Air',
        children: [
          {
            link: '/sunburst',
            name: 'Sunburst',
          },
          {
            link: '/sankey',
            name: 'Sankey',
          },
          {
            link: '/time-series',
            name: 'Time Series',
          },
          {
            link: '/bar-chart',
            name: 'Bar Chart',
          },
          {
            link: '/flight-segments',
            name: 'Flight Segments',
          },
          {
            link: '/flight-segments-per-organization',
            name: 'Flight Segments per Organization',
          },
        ],
      },
      {
        link: '/train',
        name: 'Train',
        children: [
          {
            link: '/sunburst',
            name: 'Sunburst',
          },
          {
            link: '/sankey',
            name: 'Sankey',
          },
          {
            link: '/time-series',
            name: 'Time Series',
          },
          {
            link: '/bar-chart',
            name: 'Bar Chart',
          },
          {
            link: '/train-segments',
            name: 'Train Segments',
          },
          {
            link: '/train-segments-per-organization',
            name: 'Train Segments per Organization',
          },
        ],
      },
      {
        link: '/cars',
        name: 'Cars',
      },
      // Energy
      {
        link: '/energy',
        name: 'Energy',
      },
      // Resources
      {
        link: '/food',
        name: 'Food',
      },
      {
        link: '/paper',
        name: 'Paper',
      },
      {
        link: '/water',
        name: 'Water',
      },
    ],
  },
]

function getLink(path, currentRoutes, baseUrl = '') {
  const [head, ...tail] = path.split('/')
  const r = currentRoutes.find((cr) => cr.link === `/${head}`)

  const linkItem = {
    ...r,
    link: baseUrl === '/' ? r.link : baseUrl + r.link,
  }

  if (tail.length && r.children) {
    const newPath = tail.join('/')
    return [linkItem, ...getLink(newPath, r.children, linkItem.link)]
  }

  return [linkItem]
}

const getBreadcrumb = (location) => {
  const pathWithoutTrailingSlash = location.pathname.replace(/\/+$/, '')
  const breadcrumbRoutes = getLink(pathWithoutTrailingSlash, routes)
  return breadcrumbRoutes
}

export default function Header() {
  const breadcrumb = getBreadcrumb(globalHistory.location)
  const { hasCustomJsonData } = useJsonData()

  return (
    <header tw="w-full px-4 h-20 flex flex-shrink-0 justify-between items-center bg-grey-2  text-white text-opacity-70 overflow-x-auto overflow-y-hidden">
      <div tw="flex items-center flex-shrink-0">
        {/* old gigmade.svg: <Logo tw="my-8 w-32 h-full" /> */}
        <div tw="font-logo text-3xl font-bold text-white ">
          Sustain<span tw="text-blue-gig">ability</span>
        </div>
        <div tw="ml-6 flex items-center text-lg font-bold space-x-3">
          {breadcrumb.map((route, index) => (
            <div key={`${index}-${route}`}>
              <div tw="inline-flex items-center">
                <Link
                  to={route.link}
                  css={[
                    tw`hover:underline`,
                    index === breadcrumb.length - 1 && tw`text-white`,
                  ]}
                >
                  {route.name}
                </Link>
                <RouteInfo routeName={route.name} />
              </div>
              {index !== breadcrumb.length - 1 && (
                <span tw="inline-block ml-4">/</span>
              )}
            </div>
          ))}
        </div>
      </div>

      {breadcrumb.length > 1 && (
        <div tw="ml-10 h-full flex items-center space-x-2">
          <div tw="relative">
            <HeaderTab name="Data" isActive>
              <Data />
            </HeaderTab>
            {hasCustomJsonData && (
              <div tw="p-1.5 absolute -top-2 right-4 text-[10px] leading-none uppercase font-bold tracking-wide text-black bg-yellow rounded">
                LOCAL
              </div>
            )}
          </div>
          <HeaderTab name="Experiments">
            <Experiments />
          </HeaderTab>
        </div>
      )}
    </header>
  )
}

const modalStyles = tw`flex pl-1`

function RouteInfo({ routeName }) {
  if (routeName === 'Air') {
    return (
      <InfoModal containerStyles={modalStyles}>
        <div>
          <InfoHeading text="Data Description" />
          <InfoParagraph>
            Flight segments with associated traveler information and GHG
            emissions.
          </InfoParagraph>
          <InfoParagraph>
            A heuristic computation is applied to segments to:
          </InfoParagraph>
          <InfoList>
            <InfoListItem>
              group flight segments into full trips (based on segment airports,
              dates, traveler information)
            </InfoListItem>
            <InfoListItem>
              classify trips as one-way or round-trips based on geographical
              distance of the visited airports (e.g., ZRH → JFK → BSL is a
              round-trip)
            </InfoListItem>
            <InfoListItem>
              compute one "remote destination" per trip for the "Zurich-centric"
              view used in most charts, based on trip mid-point in symmetrical
              trips, else airport with longest geographical distance from ZRH.
            </InfoListItem>
          </InfoList>

          <InfoParagraph>Data details:</InfoParagraph>
          <InfoList>
            <InfoListItem>
              "Org Unit", where relevant, is defined as: the employee's
              organization in the case of a traveling employee when known; the
              sponsoring employee's organization in the case an employee pays
              for the flight of another person; the paying organization in case
              of guests traveling through BTA.
            </InfoListItem>
            <InfoListItem>
              travelers paid for by others using the Expenses process are
              considered employees
            </InfoListItem>
            <InfoListItem>
              FTE counts are the summed up contractual FTE counts per employee
              occurring in yearly HR extracts. Counts of a given year are
              assumed to be the counts occurring in the yearly excerpt at Dec 31
              of the same year.
            </InfoListItem>
            <InfoListItem>
              emission data: atmosfair (cf Sources) provides: precise data
              estimates based on actual aircraft and flight occupancy if flight
              dates and numbers are known, and if the flight has taken place 9+
              months before the date of analysis; else, an estimate based on the
              takeoff and landing airports.
            </InfoListItem>
            <InfoListItem>
              Per atmosfair recommendation, estimated emissions are not
              recalculated even once the flight date is sufficiently in the past
              for atmosfair to have more precise data.
            </InfoListItem>
          </InfoList>
        </div>
        <div tw="mt-6">
          <InfoHeading text="Data Acquisition and System Boundary" />
          <InfoParagraph>
            The flights taken into account are all flights paid for by the
            university, including for guests.
          </InfoParagraph>
          <InfoParagraph>
            An exception are flights by students which are not taken into
            account. This applies to both student flights in connection with
            their studies (excursions, study weeks, etc.), as well as to
            international mobility (Swiss-European Mobility Program, etc.).
          </InfoParagraph>
          <InfoParagraph>
            Only those flights are taken into account that are available in the
            university's expense archive, in the BTA travel first travel agency
            and with AirPlus credit cards.
          </InfoParagraph>
        </div>

        <div tw="mt-6">
          <InfoHeading text="Sources" />
          <InfoList tw="mt-3">
            <InfoListItem>
              F&amp;S Controlling: Lists of the flights paid for by the
              university via the expense process (through the cross-reference of
              the booking run and CATSDA lists, which are provided annually)
            </InfoListItem>
            <InfoListItem>
              Expense archive: flight receipts with travel information that are
              archived in paper form in a folder, broken down by year, month and
              name, according to the time of reimbursement.
            </InfoListItem>
            <InfoListItem>
              BTA travel first: Annual export request for passenger lists
              including travel routes.
            </InfoListItem>
            <InfoListItem>
              AirPlus credit card: The monthly credit card statements are
              requested annually from the individual organizational units that
              use the credit card to book flights directly or from travel
              agencies other than BTA travel first.
            </InfoListItem>
            <InfoListItem>
              emission data by the German company{' '}
              <ExternalLink href="https://www.atmosfair.de">
                atmosfair gGmbH
              </ExternalLink>
            </InfoListItem>
          </InfoList>
        </div>

        <div tw="mt-6">
          <InfoHeading text="Data Processing" />
          <InfoParagraph>
            The data are collected through email inquiries from F&amp;S
            Controlling, BTA travel first and AirPlus Credit Card.
          </InfoParagraph>
          <InfoParagraph>
            The data in the expense archive are recorded manually using Excel
            list templates with recording instructions.
          </InfoParagraph>
          <InfoParagraph>
            Access to the expense archives is coordinated in advance with
            F&amp;S Controlling and Facility Management.
          </InfoParagraph>
          <InfoParagraph>
            All flight segment lists are combined, stripped of all traveler
            information and then passed on to atmosfair once a year for analysis
            of the data according to the VDR standard.
          </InfoParagraph>
          <InfoParagraph>
            atmosfair will provide the following evaluations for each flight
            segment: Fuel consumption + critical altitude; RFI 2; RFI 2 + 15.2%
            fuel provision; RFI 2.7; RFI 4; DEFRA; GHG GRI; ICAO; VFU; Aircraft;
            Distance; Cruise altitude; Calculation Method (city pair, flight
            number, etc.).
          </InfoParagraph>
          <InfoParagraph>
            The combined flight segment list is merged with the atmosfair
            analysis data and the personnel lists from HR, and subsequently
            anonymized using k-anonymization.
          </InfoParagraph>
          <InfoParagraph>
            Data removed by k-anonymization is labeled as "*". In contrast,
            unavailable data is labeled as "Unknown".
          </InfoParagraph>
        </div>
      </InfoModal>
    )
  }

  if (routeName === 'Energy') {
    return (
      <InfoModal containerStyles={modalStyles}>
        <div>
          <InfoHeading text="Data Description" />
          <InfoParagraph>
            Measured energy is final energy per year and energy reference area
            (era).
          </InfoParagraph>
          <InfoParagraph>
            era is the sum of all above and below ground floor areas that lie
            within the thermal building envelope and the use of which requires
            heating or air conditioning.
          </InfoParagraph>
        </div>

        <div tw="mt-6">
          <InfoHeading text="Data Acquisition and System Boundary" />
          <InfoParagraph>Only buildings owned by the canton:</InfoParagraph>
          <InfoList>
            <InfoListItem>
              Technical center: TB / TC / TE / TH / TL / TM / TP / TR / TV / TY
            </InfoListItem>
            <InfoListItem>SW</InfoListItem>
            <InfoListItem>
              Grüental: GA / GB / GC / GD / GE / GF / GG / GH / GI / GJ / GP /
              GV
            </InfoListItem>
          </InfoList>
          <InfoParagraph>
            There is no uniform procedure for collecting this data for rented
            buildings and is often not part of the contracts, so the data is not
            currently available.
          </InfoParagraph>
          <InfoParagraph>No data is available for heat recovery.</InfoParagraph>
          <InfoParagraph>
            Although the GA heat pump was installed in 2015, the data is only
            available from 2019 on as the energy meter was not connected to the
            system before.
          </InfoParagraph>
        </div>

        <div tw="mt-6">
          <InfoHeading text="Sources" />
          <InfoList tw="mt-3">
            <InfoListItem>
              era: Area management FM Speedikon of the ZHAW according to SIA
            </InfoListItem>
            <InfoListItem>
              Thermal energy: measuring points of the energy suppliers,
              visualization in the energy monitoring system of the Canton of
              Zurich (Interwatt)
            </InfoListItem>
          </InfoList>
        </div>
      </InfoModal>
    )
  }

  if (routeName === 'Food') {
    return (
      <InfoModal containerStyles={modalStyles}>
        <div>
          <InfoHeading text="Data Acquisition and System Boundary" />
          <InfoParagraph>
            Differentiation of the Toni area according to ZHAW and ZHdK is not
            possible: Conversion of the absolute amount is done according to the
            usage ratio.
          </InfoParagraph>
          <InfoParagraph>
            No differentiation by departments possible.
          </InfoParagraph>
        </div>
        <div tw="mt-6">
          <InfoHeading text="Sources" />
          <InfoList tw="mt-3">
            <InfoListItem>Commissioned caterers</InfoListItem>
          </InfoList>
        </div>
      </InfoModal>
    )
  }

  if (routeName === 'Paper') {
    return (
      <InfoModal containerStyles={modalStyles}>
        <div>
          <InfoHeading text="Data Acquisition and System Boundary" />
          <InfoParagraph>
            Reporting by supplier, and billing of printed pages to
            multi-function printers (MFPs) and in the copy center
          </InfoParagraph>
          <InfoParagraph>
            Differentiation by departments possibly by means of cost center
          </InfoParagraph>
        </div>
        <div tw="mt-6">
          <InfoHeading text="Sources" />
          <InfoList tw="mt-3">
            <InfoListItem>kdmz (supplier)</InfoListItem>
            <InfoListItem>SLV</InfoListItem>
          </InfoList>
        </div>
      </InfoModal>
    )
  }

  if (routeName === 'Water') {
    return (
      <InfoModal containerStyles={modalStyles}>
        <div>
          <InfoHeading text="Data Acquisition and System Boundary" />
          <InfoParagraph>Only buildings owned by the canton:</InfoParagraph>
          <InfoList>
            <InfoListItem>
              Technical center: TB / TC / TE / TH / TL / TM / TP / TR / TV / TY
            </InfoListItem>
            <InfoListItem>SW</InfoListItem>
            <InfoListItem>
              Grüental: GA / GB / GC / GD / GE / GF / GG / GH / GI / GJ / GP /
              GV
            </InfoListItem>
          </InfoList>
        </div>
        <div tw="mt-6">
          <InfoHeading text="Sources" />
          <InfoList tw="mt-3">
            <InfoListItem>
              Technical center water consumption (only buildings owned by the
              canton)
            </InfoListItem>
            <InfoListItem>
              Interwatt (ZHAW TB / TC / TE / TH / TL / TM / TP / TR / TV / TY)
            </InfoListItem>
            <InfoListItem>
              Water consumption SW: Interwatt (selection: ZHAW SW)
            </InfoListItem>
            <InfoListItem>
              Grüental water consumption: Interwatt (selection: ZHAW Wädenswil
              Grüental)
            </InfoListItem>
          </InfoList>
        </div>
      </InfoModal>
    )
  }

  if (routeName.includes('Segments per Organization')) {
    return (
      <InfoModal containerStyles={modalStyles}>
        <div>
          <InfoHeading text="Data Description" />
          <InfoParagraph>
            In this export, data is aggregated as follows:
          </InfoParagraph>
          <InfoList>
            <InfoListItem>
              There is one row for each org unit that exists in either baseline
              or target period
            </InfoListItem>
            <InfoListItem>
              If we split the export by personal attributes (such as gender,
              year of birth), then each org unit will get one row per each
              different attribute, and only the relevant FTE is counted in each
              row.
            </InfoListItem>
            <InfoListItem>
              As a special case, if we split by person_type, then only employees
              (for which there is HR information) will have any FTE assigned to
              them.
            </InfoListItem>
            <InfoListItem>
              Flight emissions are assigned to the org unit row accountable for
              the flight.
            </InfoListItem>
            <InfoListItem>
              If rows are further split by personal attributes as described
              above, then flights are assigned to the row that matches the
              personal attributes of the pax. (This is true even if the
              accountable org unit is not the "home" org unit of the pax.)
            </InfoListItem>
            <InfoListItem>
              A pax without a matching "split by" attribute is assigned to a row
              where the attribute is "unknown".
            </InfoListItem>
          </InfoList>
        </div>
      </InfoModal>
    )
  }

  return <></>
}
