import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import 'twin.macro'

import { loadFile } from './loadFile'
import dataAnon from './dataset-anon.json'

export type TJsonDataContext = {
  jsonData: Record<string, unknown> | null
  hasCustomJsonData: boolean
  loadJsonData: (data: string) => void
  resetJsonData: () => void
}

const JsonDataContext = createContext<TJsonDataContext | undefined>(undefined)

export function useJsonData() {
  const context = useContext(JsonDataContext)
  if (context === undefined) {
    throw new Error('usejsonData must be used within a DataProvider')
  }

  return context
}

type JsonDataProviderProps = {
  children: ReactNode
}

export function JsonDataProvider({ children }: JsonDataProviderProps) {
  const [jsonData, setJsonData] = useState<Record<string, unknown>>(null)
  const [hasCustomJsonData, setHasCustomJsonData] = useState(false)

  useEffect(() => {
    const mount = async () => {
      const file = await loadFile()

      if (file?.content) {
        setJsonData(JSON.parse(file.content))
        setHasCustomJsonData(true)
      } else {
        setJsonData(dataAnon)
        setHasCustomJsonData(false)
      }
    }
    mount()
  }, [])

  const loadJsonData = useCallback((content: string) => {
    if (content) {
      setJsonData(JSON.parse(content))
      setHasCustomJsonData(true)
    }
  }, [])

  const resetJsonData = useCallback(() => {
    setJsonData(dataAnon)
    setHasCustomJsonData(false)
  }, [])

  const value = useMemo(
    () => ({
      jsonData,
      loadJsonData,
      resetJsonData,
      hasCustomJsonData,
    }),
    [jsonData, loadJsonData, resetJsonData, hasCustomJsonData]
  )

  if (!value.jsonData) {
    return (
      <div tw="bg-blue-100 font-sans text-grey-2 text-xl font-bold fixed inset-0 flex items-center justify-center gap-2 overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          tw="w-5 h-5 animate-spin"
        >
          <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z" />
        </svg>
        <span>Loading data...</span>
      </div>
    )
  }

  return (
    <JsonDataContext.Provider value={value}>
      {children}
    </JsonDataContext.Provider>
  )
}
