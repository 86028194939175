import { ReactNode, createContext, useContext, useMemo } from 'react'
import 'twin.macro'

import type {
  Airport,
  Building,
  Car,
  Energy,
  EnergyEfficiencyAchieved,
  Food,
  Leg,
  Paper,
  TDemographics,
  TGhg,
  THr,
  TTrainStations,
  TrainsWithIndicators,
  Water,
} from './types'
import { getOrgFte } from './orgFte'
import {
  dictToObject,
  getKeyData,
  createTravelSegmentData,
  listToObject,
} from './utils'
import { TData, validateData } from './validateData'
import { useJsonData } from './jsonDataContext'

export type TDataContext = Record<string, unknown> | null

const DataContext = createContext<TDataContext | undefined>(undefined)

export function useData() {
  const context = useContext(DataContext)
  if (context === undefined) {
    throw new Error('useData must be used within a DataProvider')
  }

  return context
}

type DataProviderProps = {
  children: ReactNode
}

export function DataProvider({ children }: DataProviderProps) {
  const data = useTransformedData()

  const value = useMemo(() => data, [data])

  if (!value) {
    return (
      <div tw="bg-blue-100 font-sans text-grey-2 text-xl font-bold fixed inset-0 flex items-center justify-center gap-2 overflow-hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 -960 960 960"
          tw="w-5 h-5 animate-spin"
        >
          <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q17 0 28.5 11.5T520-840q0 17-11.5 28.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160q133 0 226.5-93.5T800-480q0-17 11.5-28.5T840-520q17 0 28.5 11.5T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Z" />
        </svg>
        <span>Processing data...</span>
      </div>
    )
  }

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}

// TODO: Rename related according to (some relevant functions getTopUnits, getTopOrgFteGroupedByYear):
// Naming for different kinds of org units:
// `orgUnit` (or `accountable..`): A, BC, DAA
// `summedOrgUnit`: ∑A, ∑BA, ∑ABA, perhaps ∑
// `topLevelOrgUnit`: A, B, C (if a sep. name is needed)
// `totalOrg`: ∑ (if a sep. name is needed)

function useTransformedData() {
  const { jsonData: data } = useJsonData()

  const value = useMemo(() => processData(data), [data])

  return value
}

function processData(data: Record<string, unknown> | null) {
  if (!data) {
    return null
  }

  try {
    validateData(data)

    const {
      airports,
      train_stations: trainStations,
      food,
      paper,
      water,
      cars,
      energy,
      energy_efficiency_achieved: energyEfficiencyAchieved,
      flight_legs: flightLegs,
      trains,
      config,
      hr,
      demographics,
      ghg_flights: ghgFlights,
      ghg_trains: ghgTrains,
    } = data as TData

    const {
      data_specs: dataSpecs,
      org_colors: orgColors,
      org_units: orgUnits,
      home_airport: homeAirport,
      home_train_station: homeTrainStation,
    } = config

    const flightKeys = [
      getKeyData(ghgFlights, dataSpecs.ghg_flights, '__ghg_flights_key'),
      ...(hr && dataSpecs.hr ? [getKeyData(hr, dataSpecs.hr, '__hr_key')] : []),
      ...(demographics && dataSpecs.demographics
        ? [getKeyData(demographics, dataSpecs.demographics, '__dem_key')]
        : []),
    ]

    const trainKeys = [
      getKeyData(ghgTrains, dataSpecs.ghg_trains, '__ghg_trains_key'),
      ...flightKeys,
    ]

    const hrEntries = listToObject<THr>(hr, dataSpecs.hr)

    const transformed = {
      airports: dictToObject<Airport>(
        airports,
        dataSpecs.airports as [string, Array<string>]
      ),
      trainStations: dictToObject<TTrainStations>(
        trainStations,
        dataSpecs.train_stations as [string, Array<string>]
      ),
      flightLegs: createTravelSegmentData<Leg>(
        flightLegs,
        dataSpecs.flight_legs,
        flightKeys
      ),
      trains: createTravelSegmentData<TrainsWithIndicators>(
        trains,
        dataSpecs.trains,
        trainKeys
      ),
      hr: listToObject<THr>(hr, dataSpecs.hr),
      demographics: listToObject<TDemographics>(
        demographics,
        dataSpecs.demographics
      ),
      ghg: {
        air: listToObject<TGhg>(ghgFlights, dataSpecs.ghg_flights),
        train: listToObject<TGhg>(ghgTrains, dataSpecs.ghg_trains),
      },
      orgFte: getOrgFte(hrEntries),
      // Site energy reference area (ERA)
      buildings: data.buildings as Record<string, Building[]>,
      // Org unit lookups
      orgUnits,
      orgColors,
      dataSpecs: {
        ...dataSpecs,
        // Required for cars download department fte
        // (keep snake case, consistent with other specs)
        org_fte: ['year', ['org code', ['fte', 'count']]],
      },
      homeLocation: {
        air: homeAirport,
        train: homeTrainStation,
      },
      // Optional
      cars: listToObject<Car>(cars, dataSpecs.cars),
      food: listToObject<Food>(food, dataSpecs.food),
      paper: listToObject<Paper>(paper, dataSpecs.paper),
      water: listToObject<Water>(water, dataSpecs.water),
      energy: listToObject<Energy>(energy, dataSpecs.energy),
      energyEfficiencyAchieved: listToObject<EnergyEfficiencyAchieved>(
        energyEfficiencyAchieved,
        dataSpecs.energy_efficiency_achieved
      ),
    }

    return transformed
  } catch (error) {
    throw error
  }
}
